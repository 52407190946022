import * as React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <main>
        <h1>Page Not Found</h1>
        <p>Can't find this page, sorry!</p>
        <p>
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <Link to="/">Go back to home page</Link>.
        </p>
      </main>
    </Layout>
  )
};

export default NotFoundPage;
